<template>
  <div v-if="isAdmin" class="playground panel-admin-body">
    <div class="title">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> <h4>US Foods - NPS Email Template</h4> </div>
      <div class="col-4"> <h4>Download Verbatims</h4> </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="previewEmail">Preview Email</button>
         </div>  
      </div>
      <div class="col-4"> 
        <a
          href="/api/v1/import-export/download-survey-verbatims"
          @click.prevent="downloadItem()"
          id="DownloadSurveyRespondentTemplate"
          :class="[this.surveySelected ? '' : 'disabled disable-link']"
          role="button"
          aria-disabled="true"
        >
          <button type="button" class="btn btn-primary mr-2" :disabled="disableButton">
            <span class="align-text-bottom"> Download <font-awesome-icon :icon="['fa', 'download']"/></span>
            <span v-show="modalLoading" class="spinner-border spinner-border-sm"></span>
          </button>
        </a>
      </div>
    </div>
    <div class="row">
      <div v-show="previewEmailClicked && !getDisplayView()" class="text-success advisor-preview-email modal-body">  
            Email is being rendered please hold......
        </div>      
        <div v-show="previewEmailClicked && getDisplayView()" class="advisor-preview-email modal-body">        
            <div>
                <div>From: {{ emailFromAddress }}</div>
                <div>Subject: {{ emailSubject }}</div>
                
            </div>
            <div class="email-body" v-html="emailBody"></div>
        </div>            
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>
<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import AdminMixins from "../mixins/AdminMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import UserService from "../services/user.service";
import AdvisorService from "../services/advisor.service";
import axios from "axios";


export default {
  name: "Playground",
  mixins: [AdminMixins, ViewMixins, DataTableMixins],
 components: {},
  props: {},
  data() {
    return {
      title: "At the Playground",
      disableButton: false,
      modalLoading: false,
      surveyId: SurveyService.getSurveyId(),
      clientTimePeriodId: 11155,
      currentSurveyName: SurveyService.getSurveyName(),
      surveySelected: true,

      advisorId: 1782,
      emailBody: "",
      emailFromAddress: "",
      emailSubject: "",
      refreshModal: true ,
      previewEmailClicked: false     
    };
  },
  methods: {
/**** Functions for WRAT-1121 Ability for Users to Download Verbatims - Start*/    
    getDownloadFilename() {
      return "Survey Verbatims - " + this.currentSurveyName;
    },    
    async downloadItem() {
      this.modalLoading = true;
      this.previewEmailClicked = false;
      this.setupDownloadItem();
      await axios({
        url: this.baseUrl + "/api/v1/import-export/download-survey-verbatims?surveyId=" + this.surveyId + "&clientTimePeriodId="+ this.clientTimePeriodId + "&userId=" + UserService.getUserId(),
        method: "GET",
        responseType: "blob"
      })
      .then(response => this.downloadItemCallback(response))
      .catch(error => this.downloadItemError(error));
      this.modalLoading = false;
    },    
/**** Functions for WRAT-1121 Ability for Users to Download Verbatims - End */        
    previewEmail: function(){
      this.previewEmailClicked = true;
      AdvisorService.previewAdvisorEmail(this.advisorId, this.previewAdvisorEmailCallback, this);
    },
    previewAdvisorEmailCallback(response){
      this.$root.processServerResponse();
      this.setDisplayView(true);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.emailFromAddress = response.data.data.from; 
        this.emailSubject = response.data.data.subject; 
        this.emailBody = response.data.data.body; 
        AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }      
    },    
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss">
</style>
